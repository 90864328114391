import React, { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Moment from "moment";
import {
  getBlendLogs,
} from "../../models/_blendlogs";
import { DownloadExcel } from "../ExcelHelper";
import clsx from "clsx";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { KTSVG } from "../../../_metronic/helpers";
import ReactSelect from "react-select";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import { Option } from '../../../_metronic/helpers/MySelect';
import { Tooltip } from "react-tooltip";
import { Toaster } from "react-hot-toast";

export default class Blendlogs extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      resetFrom: createRef(),
      resetTo: createRef(),
      filterToDate: "",
      filterFromDate: "",
      filterMachine: "",
      filterLocation: "",
      filterThickness: "",
      filterFlavor: "",
      filterFeedback: "",
      filterBlendDuration: "",
      filterCustomer: "",
      addLoading: false,
      filterLoading: false,
      downloadLoading: false,
      loading: false,
      machineSelected: null,
      customerSelected: null,
      locationSelected: null,
      locationDB: [],
      blendLogObj: {},
      machineDB: [],
      customerDB: [],
      blendLogList: [],
    };
  }

  async componentWillMount() { }

  async componentDidMount() {
    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  async componentDidUpdate() { }
  getCustomFilter = () => {
    let customFilter: any = {};
    if (this.state.filterCustomer.length > 0) {
      customFilter["customer_id"] = this.state.filterCustomer
    }


    return customFilter
  }
  getFilter = () => {
    let filter: any = {};

    if (this.state.filterMachine.length > 0) {
      filter["machine_id"] = { in: this.state.filterMachine }
    }

    if (this.state.filterLocation.length > 0) {
      filter["location_id"] = { in: this.state.filterLocation }
    }
    if (this.state.filterThickness) {
      filter["thickness"] = { like: "%" + this.state.filterThickness + "%" };
    }
    if (this.state.filterBlendDuration) {
      filter["blend_duration"] = { like: "%" + this.state.filterBlendDuration + "%" };
    }
    if (this.state.filterFeedback) {
      filter["feedback"] = { like: "%" + this.state.filterFeedback + "%" };
    }
    if (this.state.filterFlavor) {
      filter["flavor"] = { like: "%" + this.state.filterFlavor + "%" }
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["blend_date"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["blend_date"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["blend_date"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["blend_date"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter
  }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    const filter = this.getFilter();
    const customFilter = this.getCustomFilter()
    let authToken = "";
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#blendTable").DataTable().destroy();

      $("#blendTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Bearer " + authToken);
          },
          "data": function (d: any) {
            const gql = `
        query  getBlendLogs(
          $where: BlendLogResolver_GetBlendLogs_FilterInputType,
          $paginate: PaginatorArgs,
          $customFilter:BlendLogCustomFilterType!
          ) {
              getBlendLogs(where: $where, paginate: $paginate ,customFilter:$customFilter) {
                  data{
                    id
                  machineId
                  locationId
                  blendDate
                  thickness
                  blendDuration
                  flavor
                  feedback
                  machine {
                    id
                    name
                 }
                  location {
                    id
                    name
                  }
                }
                totalPages
                total 
                perPage
                page
                }
        }
        `;

            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            const query = {
              "operationName": null,
              "query": gql,
              "variables": {
                "where": filter,
                paginate: {

                  "page": currentPage,
                  "per_page": length

                },
                "customFilter": customFilter
              }
            };
            return JSON.stringify(query);
          },
          "dataSrc": function (json) {

            json.recordsTotal = json.data.getBlendLogs.total;
            json.recordsFiltered = json.data.getBlendLogs.total;

            const rows: any[] = [];
            const records = json.data.getBlendLogs.data;

            let counter = 0;
            for (const record of records) {
              counter++
              const tempRecord = {
                id: counter,
                recordId: record.id,
                machine: record.machine.name,
                location: record.location.name,
                blendDate: record?.blendDate ? Moment.utc(record.blendDate).local().format("YYYY-MM-DD HH:mm:ss") : '',
                thickness: record.thickness,
                flavor: record.flavor,
                blendDuration: record?.blendDuration ? parseFloat(record.blendDuration).toFixed(2) : '',
                feedback: record.feedback,
                record: record,
              };
              const subRecords = record.subTasks ?? "";
              for (const subRecord of subRecords) {
                subRecord["record"] = { ...subRecord };
              }
              rows.push(tempRecord);
            }
            // this.setState({
            //   ticketsList: rows,
            // });
            return rows;
          }
        },
        // data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "machine",
            render: function (data, type, row) {
              return row.machine;
            },
          },
          {
            name: "location",
            render: function (data, type, row) {
              return row.location;
            },
          },
          {
            name: "blendDate",
            render: function (data, type, row) {
              return row.blendDate;
            },
          },
          {
            name: "thickness",
            render: function (data, type, row) {
              return row.thickness;
            },
          },
          {
            name: "blendDuration",
            render: function (data, type, row) {
              return row.blendDuration;
            },
          },
          {
            name: "flavor",
            render: function (data, type, row) {
              return row.flavor;
            },
          },
          {
            name: "feedback",
            render: function (data, type, row) {
              return row.feedback;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        // order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          const title: any = [];
          $("#blendTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getFilterRecords = async () => {
    const {customers,locations,machines } = this.props;
    this.setState({
      customerDB: customers,
      machineDB: machines,
      locationDB: locations
    })
  }

  handleDownloadExcel = async () => {
    this.setState({
      downloadLoading: true
    })
    const filter = this.getFilter();
    const customFilter = this.getCustomFilter()
    const result: any = await getBlendLogs(filter, undefined, customFilter);
    let counter = 0;
    const excelRecords = [];

    for (const record of result.data) {
      counter++
      const tempRecord = {
        "Sr.No": counter,
        "Blend Id": record.id,
        "Machine Name": record.machine.name,
        "Location Name": record.location.name,
        "Blend Date": Moment.utc(record.blendDate).local().format("YYYY-MM-DD HH:mm:ss"),
        "Thickness": record.thickness,
        "Flavor": record.flavor,
        "Blend Duration": parseFloat(record.blendDuration).toFixed(2),
        "Feedback": record.feedback,
      };
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "BlendLogs");
    this.setState({
      downloadLoading: false
    })
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterMachine: "",
      filterLocation: "",
      filterFeedback: "",
      filterThickness: "",
      filterFlavor: "",
      filterCustomer: "",
      machineSelected: null,
      customerSelected: null,
      locationSelected: null,
      filterBlendDuration: ""
    });
    await this.getFilterRecords();
    this.renderDataTable([]);

    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Blend Logs
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#blend-filter"
                aria-expanded="false"
                aria-controls="blend-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Blend Logs</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="collapse" id="blend-filter" aria-labelledby="blend-filter">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customer Name</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterCustomer"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerSelected: selected,
                        filterCustomer: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.customerSelected}
                    options={Array.from(this.state.customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name.toLowerCase(),
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location Name</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterLocation"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Machines</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterMachine"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        machineSelected: selected,
                        filterMachine: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.machineSelected}
                    options={Array.from(this.state.machineDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: `${value.location?.name ?? ""} (${value?.serialNumber ?? ""
                            })`,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Thickness
                  </label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterThickness"
                    value={this.state.filterThickness}
                    onChange={(event: any) => {
                      this.setState({
                        filterThickness: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Blend duration
                  </label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterBlendDuration"
                    value={this.state.filterBlendDuration}
                    onChange={(event: any) => {
                      this.setState({
                        filterBlendDuration: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Flavor
                  </label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterFlavor"
                    value={this.state.filterFlavor}
                    onChange={(event: any) => {
                      this.setState({
                        filterFlavor: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Feedback
                  </label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterFeedback"
                    value={this.state.filterFeedback}
                    onChange={(event: any) => {
                      this.setState({
                        filterFeedback: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Blend Logs
                </h3>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  onClick={this.handleDownloadExcel}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                >
                  {this.state.downloadLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.downloadLoading && (
                    <span className="indicator-label"> <KTSVG
                      path="/media/icons/duotune/files/fil021.svg"
                      className="svg-icon-4 me-2"
                    />
                      Export Excel</span>
                  )}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="blendTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="srNo text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr.No
                          <Tooltip anchorSelect=".srNo" className="tooltip-list" >
                            This field displays the unique identifier assigned by the software to each blend.
                          </Tooltip>
                        </th>
                        <th className="machineName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine Name
                          <Tooltip anchorSelect=".machineName" className="tooltip-list " >
                            Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="locationName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location  Name
                          <Tooltip anchorSelect=".locationName" className="tooltip-list " >
                            Displays the name of the specific branch or location of the customer
                          </Tooltip>
                        </th>
                        <th className="blendDate text-capitalize font-weight-bolder opacity-7 ps-2">
                          Blend Date
                          {/* <Tooltip anchorSelect=".blendDate" className="tooltip-list " >
                          Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="thickness text-capitalize font-weight-bolder opacity-7 ps-2">
                          Thickness
                          <Tooltip anchorSelect=".thickness" className="tooltip-list " >
                            3- Thin 4- Normal 5- Thick
                          </Tooltip>
                        </th>
                        <th className="blendDuration text-capitalize font-weight-bolder opacity-7 ps-2">
                          Blend Duration
                          <Tooltip anchorSelect=".blendDuration" className="tooltip-list " >
                            This field indicates the duration, in seconds, it took for the machine to prepare the Smoodi
                          </Tooltip>
                        </th>
                        <th className="flavor text-capitalize font-weight-bolder opacity-7 ps-2">
                          Flavor
                          <Tooltip anchorSelect=".flavor" className="tooltip-list " >
                            BB- Brain Boost TT- Tropical Vibes GG- Green Energizer
                          </Tooltip>
                        </th>
                        <th className="feedbacks text-capitalize font-weight-bolder opacity-7 ps-2">
                          Feedback
                          <Tooltip anchorSelect=".feedbacks" className="tooltip-list " >
                            This field reflects the rating given by the customer to the Smoodi in the survey presented on the machine after blending. A rating of '0' indicates that the customer chose not to answer the survey.
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
        </div>
      </>
    );
  }
}
