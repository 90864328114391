import { FC, Suspense, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchQuery, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import { getServiceEngineersForFilter } from "../../models/_user";
import * as yup from "yup";
import Select from 'react-select'
import Datetime from 'react-datetime';
import Moment from 'moment';
import { assignTicket } from '../../models/_assignTickets';
import { graphql } from 'babel-plugin-relay/macro';
import { ReAssignModal_getTicketQuery } from './__generated__/ReAssignModal_getTicketQuery.graphql';

type Props = {
  reAssignModal: boolean
  currentTicketId: string
  refreshTable?: any
  handleCloseReAssign: () => void
}

export const getTicketListById = graphql`
 query ReAssignModal_getTicketQuery($id:String!){
  getTicket(id:$id){
    id
    uuid
    assignTo {
      id
      firstName
    }
  }
}
`
const reAssignLocationSchema = yup.object({
  assignToId: yup.string().required("Assign to is required"),
  scheduleStartDate: yup.string().required("Schedule start date to is required"),
  scheduleEndDate: yup.string().required("Schedule end date to is required"),
})
const ReAssignModal: FC<Props> = ({
  reAssignModal,
  currentTicketId,
  refreshTable,
  handleCloseReAssign
}) => {
  const navigate = useNavigate()
  const environment = useRelayEnvironment();

  if (!currentTicketId) {
    navigate("/tickets/list");
  }

  const refetchTicket = async (): Promise<void> => {
    try {
      await fetchQuery(environment, getTicketListById, { id: currentTicketId }).toPromise();
      console.log("Successfully updated cache with the latest ticket data.");
    } catch (error) {
      console.error("Error refetching ticket data:", error);
    }
  };

  const TicketRecord = useLazyLoadQuery<ReAssignModal_getTicketQuery>(
    getTicketListById,
    {
      id: currentTicketId ?? ""

    },
    {
      fetchPolicy: "store-or-network",
    },
  )

  const ticket: any = TicketRecord?.getTicket ?? []
  if (ticket == null) {
    navigate("/tickets/list");
  }
  const [loading, setLoading] = useState(false);
  const [assignDB, setAssignDB] = useState([])
  const [reAssignLocationEdit] = useState({
    id: ticket?.id,
    assignToId: "",
    lastAssigneToComment: "",
    scheduleStartDate: new Date(),
    scheduleEndDate: new Date()
  })

  const fetchData = async () => {
    try {
      const assignRecord = await getServiceEngineersForFilter();
      const assignResult: any = assignRecord ? assignRecord : [];
      assignResult.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setAssignDB(assignResult)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Suspense>
        <Modal
          open={reAssignModal}
          onClose={() => handleCloseReAssign()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={reAssignLocationEdit}
            validationSchema={reAssignLocationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              const scheduleStartDate = Moment(values.scheduleStartDate).utc().format("YYYY-MM-DD HH:mm:ss")
              const endDate = Moment(values.scheduleEndDate).utc().format("YYYY-MM-DD HH:mm:ss")
              let hasErrors = false;
              if (Moment(values.scheduleStartDate).utc() > Moment(values.scheduleEndDate).utc()) {
                hasErrors = true;
                toast.error("Schedule end time should be bigger than start time");
              }
              if (scheduleStartDate === "") {
                if (endDate !== "") {
                  hasErrors = true;
                  toast.error("Schedule start time is required");
                }
              }
              if (hasErrors) {
                console.log("inside has error");
                return false;
              }
              setLoading(true);
              const $input = {
                id: values.id,
                assignToId: values.assignToId,
                comment: values.lastAssigneToComment,
                scheduleStartDate: scheduleStartDate,
                scheduleEndDate: endDate
              };
              const result = await assignTicket($input);
              if (result?.data?.data?.assignTicket) {
                refetchTicket();
                toast.success("Assign to updated successfully");
                setLoading(false)
                handleCloseReAssign()
                resetForm()
                if (typeof refreshTable !== 'undefined') {
                  refreshTable()
                }
              } else {
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
              }
            }
            }

          >
            {({
              handleChange,
              handleSubmit,
              values,
              setFieldValue,
            }) =>
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate>
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Re-assign - {ticket.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-6 pt-2 mb-4">
                      <h4 className="text-gray-800">
                        Currently Assigned To :
                        <span className="mt-2 text-muted ml-3 ms-3">
                          {ticket.assignTo.firstName}
                        </span>
                      </h4>
                    </div>
                    <div className="col-lg-6 col-12 p-2 mb-3">
                      <label className="form-label fs-4 fw-bold">Schedule Start Time</label>
                      <Datetime
                        closeOnSelect
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        initialValue={values.scheduleStartDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("scheduleStartDate", dt)
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="scheduleStartDate" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 p-2 mb-3">
                      <label className="form-label fs-4 fw-bold">Schedule End Time</label>
                      <Datetime
                        // isValidDate={(current) => {
                        //   let customDate = Moment(values.scheduleStartDate, "YYYY-MM-DD HH:mm:ss")
                        //   return (
                        //     current && current === customDate
                        //   );
                        // }}
                        closeOnSelect
                        //dateFormat={false}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        initialValue={values.scheduleEndDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("scheduleEndDate", dt)
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="scheduleEndDate" />
                      </div>
                    </div>
                    <div className="col-12 mt-2 pt-2 mb-3">
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="assignToId"
                        placeholder="New Assign To"
                        onChange={(event: any) => {
                          setFieldValue("assignToId", event.value)
                        }}
                        options={Array.from(assignDB).map(
                          (value: any) => {
                            return {
                              value: value.id,
                              label: value.firstName,
                            };
                          }
                        )}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="assignToId" />
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <label className="form-label fw-bold d-block fs-4">
                        Comment
                      </label>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="lastAssigneToComment"
                          value={values.lastAssigneToComment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer mt-6 p-0 justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">SUBMIT</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            }
          </Formik>

        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>

    </div>
  )
}

export default ReAssignModal
