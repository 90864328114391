import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import MachinesList from "./MachinesList";

export interface Location {
  id: string;
  uuid: string;
  name: string;
}

export interface Machine {
  id: string;
  uuid: string;
  serialNumber: string;
  name: string;
  locationId: string;
}

export interface Region {
  id: string;
  name: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isRegionalManager: boolean;
}

export interface Blender {
  id: string;
  title: string;
  mediaUrl: string;
  description: string;
}

export interface Board {
  id: string;
  mediaUrl: string;
  description: string;
  title: string;
}

export interface MarketSegment {
  id: string;
  name: string;
}

export interface CupHolder {
  id: string;
  title: string;
  mediaUrl: string;
  description: string;
}

export interface LocationType {
  id: string;
  name: string;
  description: string;
  isDefault: boolean;
}

export interface MachineStatus {
  id: string;
  name: string;
  description: string;
}

export interface TubeShield {
  id: string;
  name: string;
  mediaUrl: string;
  description: string;
}

interface QueryResponse {
  getLocationList: Location[];
  getMachineList: Machine[];
  getRegions: Region[];
  getHardwareVersionList: string[];
  getUsers: User[];
  getBlendersList: Blender[];
  getBoardsList: Board[];
  getMarketSegment: MarketSegment[];
  getCupHoldersList: CupHolder[];
  getLocationTypes: LocationType[];
  getMachineStatus: MachineStatus[];
  getTubeShieldList: TubeShield[];
}

export type MachineParentComponentQuery = OperationType & {
  response: QueryResponse;
  variables: {
    isRegionalManager: boolean;
  };
};

class MachineParentComponent extends React.Component {
  render() {
    return (
      <QueryRenderer<MachineParentComponentQuery>
        environment={environment}
        query={graphql`
          query MachineParentComponentQuery($isRegionalManager: Boolean) {
            getLocationList {
              id
              uuid
              name
            }
            getMachineList {
              id
              uuid
              serialNumber
              name
              locationId
            }
            getRegions {
              id
              name
            }
            getHardwareVersionList
            getUsers(isRegionalManager: $isRegionalManager) {
              id
              firstName
              lastName
              isActive
              isRegionalManager
            }
            getBlendersList {
              id
              title
              mediaUrl
              description
            }
            getBoardsList {
              id
              mediaUrl
              description
              title
            }
            getMarketSegment {
              id
              name
            }
            getCupHoldersList {
              id
              title
              mediaUrl
              description
            }
            getLocationTypes {
              id
              name
              description
              isDefault
            }
            getMachineStatus {
              id
              name
              description
            }
            getTubeShieldList {
              id
              name
              mediaUrl
              description
            }
          }
        `}
        variables={{ isRegionalManager: true }}
        render={({ error, props }) => {
          if (error) {
            return (
              <div className="error-container">
                Error loading machine data: {error.message}
              </div>
            );
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }

          const {
            getLocationList,
            getMachineList,
            getUsers,
            getRegions,
            getHardwareVersionList,
            getBlendersList,
            getBoardsList,
            getMarketSegment,
            getCupHoldersList,
            getLocationTypes,
            getMachineStatus,
            getTubeShieldList
          } = props;
          return (
            <MachinesList
              machines={getMachineList}
              locations={getLocationList}
              users={getUsers}
              regions={getRegions}
              hardwareVersions={getHardwareVersionList}
              blenders={getBlendersList}
              boards={getBoardsList}
              marketSegment={getMarketSegment}
              cupHolders={getCupHoldersList}
              locationTypes={getLocationTypes}
              machineStatus={getMachineStatus}
              tubeShields={getTubeShieldList}
            />
          );
        }}
      />
    );
  }
}

export default MachineParentComponent;