/**
 * @generated SignedSource<<890e2dee73d83b7f1d4831e046f0c7b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TicketLogParentComponentQuery$variables = {};
export type TicketLogParentComponentQuery$data = {
  readonly getMachineList: ReadonlyArray<{
    readonly id: string;
    readonly locationId: string | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly uuid: string | null;
  }>;
  readonly getMachineStatus: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly getTicketSources: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getUsers: ReadonlyArray<{
    readonly firstName: string;
    readonly id: string;
    readonly isActive: boolean;
    readonly isRegionalManager: boolean;
    readonly lastName: string | null;
  }>;
};
export type TicketLogParentComponentQuery = {
  response: TicketLogParentComponentQuery$data;
  variables: TicketLogParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getMachineList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserObjectType",
    "kind": "LinkedField",
    "name": "getUsers",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRegionalManager",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TicketSourceObjectType",
    "kind": "LinkedField",
    "name": "getTicketSources",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineStatusObjectType",
    "kind": "LinkedField",
    "name": "getMachineStatus",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TicketLogParentComponentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TicketLogParentComponentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9961872d90581b4cddff4f7ddd44a033",
    "id": null,
    "metadata": {},
    "name": "TicketLogParentComponentQuery",
    "operationKind": "query",
    "text": "query TicketLogParentComponentQuery {\n  getMachineList {\n    id\n    uuid\n    serialNumber\n    name\n    locationId\n  }\n  getUsers {\n    id\n    firstName\n    lastName\n    isActive\n    isRegionalManager\n  }\n  getTicketSources {\n    id\n    name\n  }\n  getMachineStatus {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3975a685e6fc68b977f87764dee3ef3";

export default node;
