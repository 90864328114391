import React, {useState, useRef} from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactSelect from "react-select";
import {KTSVG} from "../../../_metronic/helpers";
import {Option} from "../../../_metronic/helpers/MySelect";
import ConsumptionByFlavorChart from "./ConsumptionByFlavorChart";
import FlavorConsumptionByTimeOfDayChart from "./FlavorConsumptionByTimeOfDayChart";
import YearWiseBarChart from "./YearwiseBarChart";
import DayWiseBarChart from "./DayWiseBarChart";
import MonthwiseBarChart from "./MonthwiseBarChart";
import {
  getAnnualConsumption,
  getConsumptionByFlavor,
  getDailyConsumption,
  getFlavorConsumptionByTimeOfDay,
  getMonthlyConsumption,
  getMonthlyConsumptionByMachine,
} from "../../models/_customerDashboard";
import MonthlyConsumptionByMachine from "./MonthlyConsumptionByMachine";
import { ConsumptionSummaryProps } from "./ConsumptionSummaryParentComponent";

const CustomerDashboard: React.FC<ConsumptionSummaryProps> = (props) => {
  const [addLoading, setAddLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [machineDB, setMachineDB] = useState<any>([]);
  const [customerDB, setCustomerDB] = useState<any>([]);
  const [machineSelected, setMachineSelected] = useState(null);
  const [customerSelected, setCustomerSelected] = useState<any>(null);
  const [filterMachine, setFilterMachine] = useState("");
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");
  const [chart, setChart] = useState([]);
  const [monthChart, setMonthChart] = useState([]);
  const [flavorConsumption, setFlavorConsumption] = useState([]);
  const [consumptionByFlavor, setConsumptionByFlavor] = useState([]);
  const [annualConsumption, setAnnualConsumption] = useState([]);
  const [monthlyConsumptionByMachine, setMonthlyConsumptionByMachine] =
    useState([]);

  const resetfromDate = useRef<any>(null);
  const resetTo = useRef<any>(null);
  const fetchData = async () => {
    const {machines,customers} = props;
    setMachineDB(machines);
    setCustomerDB(customers);
  };

  const searchData = async (
    filterMachine: any,
    filterFromDate: any,
    filterToDate: any,
    filterCustomer: any
  ) => {
    const filters = {
      machine_id: filterMachine?.length > 0 ? filterMachine : undefined,
      from_date: filterFromDate,
      to_date: filterToDate,
      customer_id: filterCustomer?.length > 0 ? filterCustomer : undefined,
    };

    const dailyConsumptionFilter = {
      from_date: filters.from_date,
      to_date: filters.to_date,
      machine_id: filters.machine_id,
      customer_id: filters.customer_id,
    };

    const consumptionByFlavorFilter = {
      machine_id: filters.machine_id,
      customer_id: filters.customer_id,
      from_date: filters.from_date,
      to_date: filters.to_date,
    };

    const monthlyConsumptionFilter = {
      machine_id: filters.machine_id,
      customer_id: filters.customer_id,
    };

    const flavorConsumptionByTimeOfDayFilter = {
      machine_id: filters.machine_id,
      from_date: filters.from_date,
      customer_id: filters.customer_id,
      to_date: filters.to_date,
    };

    const annualConsumptionFilter = {
      machine_id: filters.machine_id,
      customer_id: filters.customer_id,
    };
    const monthlyConsumptionByMachineFilter = {
      machine_id: filters.machine_id,
      customer_id: filters.customer_id,
    };
    const fetchAndSetData = async () => {
      const [
        dailyData,
        monthlyData,
        flavorData,
        consumptionByFlavorData,
        annualData,
        monthlyMachineData,
      ] = await Promise.all([
        getDailyConsumption(dailyConsumptionFilter),
        getMonthlyConsumption(monthlyConsumptionFilter),
        getFlavorConsumptionByTimeOfDay(flavorConsumptionByTimeOfDayFilter),
        getConsumptionByFlavor(consumptionByFlavorFilter),
        getAnnualConsumption(annualConsumptionFilter),
        getMonthlyConsumptionByMachine(monthlyConsumptionByMachineFilter),
      ]);

      const dailyChartData =
        dailyData && dailyData !== undefined
          ? dailyData.map(({day, averageBlends}: any) => ({
              day,
              averageBlends: parseFloat(averageBlends),
            }))
          : [];

      setChart(dailyChartData);

      const monthlyChartData =
        monthlyData && monthlyData !== undefined
          ? monthlyData.map(({month, totalBlends}: any) => ({
              month,
              totalBlends: parseFloat(totalBlends),
            }))
          : [];

      setMonthChart(monthlyChartData);

      const flavorConsumptionChartData =
        flavorData && flavorData !== undefined
          ? flavorData.map(
              ({
                hourOfTheDay,
                name,
                totalConsumption,
                flavorColorCode,
              }: any) => ({
                hourOfTheDay,
                name,
                totalConsumption,
                flavorColorCode,
              })
            )
          : [];

      setFlavorConsumption(flavorConsumptionChartData);

      const consumptionByFlavorChartData =
        consumptionByFlavorData && consumptionByFlavorData !== undefined
          ? consumptionByFlavorData.map(
              ({flavor, consumptionRate, flavorColorCode}: any) => ({
                flavor,
                consumptionRate,
                flavorColorCode,
              })
            )
          : [];

      setConsumptionByFlavor(consumptionByFlavorChartData);

      const annualChartData =
        annualData && annualData !== undefined
          ? annualData.map(({year, totalBlends}: any) => ({
              year,
              totalBlends,
            }))
          : [];
      setAnnualConsumption(annualChartData);

      const monthlyMachinCharteData =
        monthlyMachineData && monthlyMachineData !== undefined
          ? monthlyMachineData?.map(({machine, month, consumption}: any) => ({
              machine,
              month,
              consumption,
            }))
          : [];

      setMonthlyConsumptionByMachine(monthlyMachinCharteData);
    };
    setAddLoading(true);
    await fetchAndSetData();
    setAddLoading(false);
  };

  const removeSearch = async () => {
    setFilterLoading(true);
    resetfromDate.current.state.inputValue = "";
    resetTo.current.state.inputValue = "";

    setMachineSelected(null);
    setCustomerSelected(null);
    setFilterMachine("");
    setFilterCustomer("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterLoading(false);

    searchData("", "", "", "");
  };

  React.useEffect(() => {
    fetchData();
    searchData(filterMachine, filterFromDate, filterToDate, filterCustomer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="fw-bolder card-label fw-bold fs-3">
            {" "}
            Consumption Summary
          </span>
        </h3>
        <div className="col-12">
          <div className="card mt-6 mx-xl-4">
            <div className="card-body w-100 p-5">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#blend-filter"
                aria-expanded="false"
                aria-controls="blend-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>

              <div
                className="collapse"
                id="blend-filter"
                aria-labelledby="blend-filter"
              >
                <div className="card">
                  <div className="card-body w-100 p-1 px-2">
                    <div className="row mt-6">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          From Date
                        </label>
                        <Datetime
                          ref={resetfromDate}
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={filterFromDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFilterFromDate(dt);
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          To Date
                        </label>
                        <Datetime
                          ref={resetTo}
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={filterToDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFilterToDate(dt);
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Machine Name
                        </label>

                        <ReactSelect
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          isMulti
                          name="filterMachine"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(selected: any) => {
                            const value = selected?.map((e: any) => e.value);
                            setMachineSelected(selected);
                            setFilterMachine(value);
                          }}
                          components={{
                            Option,
                          }}
                          value={machineSelected}
                          options={Array.from(machineDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: `${value.location?.name ?? ""} (${
                                value?.serialNumber ?? ""
                              })`,
                            };
                          })}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Customers
                        </label>
                        <ReactSelect
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          isMulti
                          name="filterCustomer"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(selected: any) => {
                            const value = selected?.map((e: any) => e.value);
                            setCustomerSelected(selected);
                            setFilterCustomer(value);
                          }}
                          components={{
                            Option,
                          }}
                          value={customerSelected}
                          options={Array.from(customerDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.name,
                            };
                          })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <button
                          type="submit"
                          onClick={() =>
                            searchData(
                              filterMachine,
                              filterFromDate,
                              filterToDate,
                              filterCustomer
                            )
                          }
                          className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                        >
                          {addLoading && (
                            <span
                              className="indicator-progress"
                              style={{display: "block"}}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!addLoading && (
                            <span className="indicator-label">ADD FILTER</span>
                          )}
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <button
                          type="submit"
                          onClick={() => removeSearch()}
                          className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                        >
                          {filterLoading && (
                            <span
                              className="indicator-progress"
                              style={{display: "block"}}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!filterLoading && (
                            <span className="indicator-label">
                              RESET FILTER
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card mt-6 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div>
                <div className="row mx-md-n5 mt-4">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2">Daily Consumption</h4>
                      <DayWiseBarChart data={chart} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2">Monthly Consumption</h4>
                      <MonthwiseBarChart data={monthChart} />
                    </div>
                  </div>
                </div>
                <div className="row mx-md-n5 mt-4">
                  <div className="col">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2">
                        Flavor Consumption By TIme of day
                      </h4>
                      <FlavorConsumptionByTimeOfDayChart
                        data={flavorConsumption}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mx-md-n5 mt-4">
                  <div className="col">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2">Consumption By Flavor</h4>
                      <ConsumptionByFlavorChart data={consumptionByFlavor} />
                    </div>
                  </div>
                </div>

                <div className="row mx-md-n5 mt-4">
                  <div className="col">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2"> Annual Consumption </h4>
                      <YearWiseBarChart data={annualConsumption} />
                    </div>
                  </div>
                </div>

                <div className="row mx-md-n5 mt-4">
                  <div className="col">
                    <div className="p-3 border bg-light">
                      <h4 className="mt-2"> Monthly Consumption By Machine</h4>
                      <MonthlyConsumptionByMachine
                        data={monthlyConsumptionByMachine}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
