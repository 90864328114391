import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateUserInput {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  password: string;
  isDeveloper: boolean;
  isRegionalManager: boolean;
  canHandleServiceTicket: boolean;
}
export async function createUser(input: CreateUserInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `mutation createUser(
              $input:CreateUserInput!
          ){
              createUser(
                  createUserInput:$input
              ) 
          }`,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function createRole(name: string, permissions: string[]) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createRole (
           $name: String!
           $permissions:[String!]!
        ){
          createRole(
            createRoleInput: {
                name: $name
                permissions:$permissions
              }
            )
        }
        `,
      variables: {
        name,
        permissions,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getRoles() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getRoles {
              id
              name
              slug
              permissions {
                id
                name
              }
            }
          }
          `,
      variables: {},
    },
  });
  return data?.data?.data?.getRoles;
}
export async function getUsers() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query {
                getUsers {
                  id
                  firstName
                  lastName
                  email
                  roleId
                  slackId
                  createdAt
                  isActive
                  isDeveloper
                  isRegionalManager
                  isSalesPerson
                  canHandleServiceTicket
                  role {
                    id
                    name
                    
                  }
                  }
              }`,
      variables: {},
    },
  });
  return data?.data?.data?.getUsers;
}

export async function updateUserRegionalManagerStatus(
  id: string,
  isRegionalManager: boolean
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
         mutation ($id: String!, $isRegionalManager: Boolean! ) {
          updateUserRegionalManagerStatus(
            updateUserRegionalManagerStatus: { id:$id, isRegionalManager:$isRegionalManager }
                )
              }
              `,
      variables: {
        id,
        isRegionalManager,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateUserServiceEngineerStatus(
  id: string,
  canHandleServiceTicket: boolean
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
         mutation ($id: String!, $canHandleServiceTicket: Boolean! ) {
          updateUserServiceEngineerStatus(
            updateUserServiceEngineerStatus: { id:$id, canHandleServiceTicket:$canHandleServiceTicket }
                )
              }
              `,
      variables: {
        id,
        canHandleServiceTicket,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getServiceEngineers() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query {
                getServiceEngineers {
                  id
                  firstName
                  lastName
                  email
                  roleId
                  slackId
                  resetPasswordKey
                  createdAt
                  updatedAt
                  isActive
                  isDeveloper
                  role {
                    id
                    name
                    
                  }
                  }
              }`,
      variables: {},
    },
  });
  return data?.data?.data?.getServiceEngineers;
}


export async function getServiceEngineersForFilter() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query {
                getServiceEngineers {
                    id
                    firstName
                  }
              }`,
      variables: {},
    },
  });
  return data?.data?.data?.getServiceEngineers;
}

export async function updateUserStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
         mutation ($id: String!, $isActive: Boolean! ) {
          updateUserStatus(
            updateUserStatusInput: { id: $id, isActive: $isActive }
                )
              }
              `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateUserDeveloperStatus(
  id: string,
  isDeveloper: boolean
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
         mutation ($id: String!, $isDeveloper: Boolean! ) {
          updateUserDeveloperStatus(
            updateUserIsDeveloperInput: { id:$id, isDeveloper:$isDeveloper }
                )
              }
              `,
      variables: {
        id,
        isDeveloper,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getPermissions() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query {
                getPermissions {
                  id
                  name
                }
                }
              `,
      variables: {},
    },
  });
  return data?.data?.data?.getPermissions;
}

export async function updatePermission(roleId: string, permissions: string[]) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
    mutation updateRolePermissions(
      $roleId:String!
      $permissions: [String!]!
    ){
      updateRolePermissions(
        updateRolePermissions: {
          roleId:$roleId
          permissions:$permissions
        }
      ) 
    }`,
      variables: {
        roleId,
        permissions,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateUserPassword(id: string, password: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
    mutation updateUserPassword(
      $id:String!
      $password:String!
     
    ){
      updateUserPassword(
        updateUserPasswordInput: {
          id:$id
          password:$password
        }
      )}
    `,
      variables: {
        id,
        password,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

interface UpdateUserInput {
  id: string;
  firstName: string;
  lastName: string;
  roleId: string;
}
export async function updateUser(input: UpdateUserInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `mutation updateUser(
              $input:UpdateUserInput!
            ){
            updateUser(
              updateUserInput: $input
            )
          }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function changePassword(
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              mutation changePassword(
                $oldPassword: String!
                $newPassword: String!
                $confirmNewPassword: String!
              ){
                changePassword(
                  changePasswordInput: {
                    oldPassword: $oldPassword
                    newPassword: $newPassword
                    confirmNewPassword: $confirmNewPassword
                  }
                )
              } `,
      variables: {
        oldPassword,
        newPassword,
        confirmNewPassword,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getModuleDataDisplaySettingByKey(
  metaKey: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getModuleDataDisplaySettingByKey($metaKey:String!){
          getModuleDataDisplaySettingByKey(metaKey:$metaKey) {
            id
            metaKey
            metaValue
          }
        }
      `,
      variables: {
        metaKey,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data?.data?.data?.getModuleDataDisplaySettingByKey;
}

export async function updateModuleDataDisplaySettingByKey(metaKey: string, metaValue: [string]) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation userWiseModuleDataDisplaySettings(
          $metaKey: String!
          $metaValue: [String!]!
        ) {
          userWiseModuleDataDisplaySettings(
            userWiseModuleDataDisplaySettingInput: {
              metaKey: $metaKey
              metaValue: $metaValue
            } 
          )
        } 
      `,
      variables: { metaKey, metaValue },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function updateUserSalesPersonStatus(id: string, isSalesPerson: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
         mutation ($id: String!, $isSalesPerson: Boolean! ) {
          updateUserSalesPersonStatus(
            updateUserSalesPersonStatus: { id: $id, isSalesPerson: $isSalesPerson }
                )
              }
              `,
      variables: {
        id,
        isSalesPerson,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}