import { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactSelect from "react-select";
import { Option } from "../../../_metronic/helpers/MySelect";
import toast, { Toaster } from "react-hot-toast";
import { getOfflineBlendLogFile, requestForOfflineBlendLogData } from "../../models/_blendlogs";
import moment from "moment";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";

export default class BlendLogsDownload extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      resetFromDate: createRef(),
      resetToDate: createRef(),
      fromDate: "",
      toDate: "",
      machineIds: [],
      locationIds: [],
      customerIds: [],
      machineSelected: null,
      customerSelected: null,
      locationSelected: null,
      addLoading: false,
      downloadLoading: false,
      loading: false,
      locationDB: [],
      machineDB: [],
      customerDB: [],
      statusDB: [
        {
          id: "IN_PROCESS",
          value: "In Process",
        },
        {
          id: "PENDING",
          value: "Pending",
        },
        {
          id: "READY_FOR_DOWNLOAD",
          value: "Ready for Download",
        },
      ],
    };
  }

  async componentWillMount() {}

  async componentDidMount() {
    this.renderDataTable([]);
    const _ = this;
    $(document).ready(function () {
      $("body").off("click", ".downloadBtn").on("click", ".downloadBtn", async function () {
        const key = $(this).attr("data-key");
        if(key) {
          const path = key.split("s3.amazonaws.com/")[1];
          _.handleFileDownload(path);
        };
      });
      }); 
  }

  async componentDidUpdate() {}

  fetchData = async () => {
    let {customers,locations,machines } = this.props;
    this.setState({
      customerDB: customers,
      machineDB: machines,
      locationDB: locations
    })
  };


  renderDataTable = async(rows: any) => {
    this.fetchData();
    let {customers,locations,machines } = this.props;
    let authToken = "";
    let _state = this.state;
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#offlineBlendLogTable").DataTable().destroy();

      $("#offlineBlendLogTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        ajax: {
          url: process.env.REACT_APP_API_URL,
          type: "POST",
          contentType: "application/json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + authToken);
          },
          data: function (d: any) {
            const gql = `
                        query getOfflineBlendLogs(
                              $paginate: PaginatorArgs
                              ) {
                                  getOfflineBlendLogs(paginate: $paginate) {
                                    data{
                                      id
                                      fromDate
                                      toDate
                                      filePath
                                      machineIds
                                      customerIds
                                      locationIds
                                      status
                                    }
                                    totalPages
                                    total
                                    perPage
                                    page
                                    }
                            }
                        `;

            const currentPage = d?.start ? d.start / d.length + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            const query = {
              operationName: null,
              query: gql,
              variables: {
                paginate: {
                  page: currentPage,
                  per_page: length,
                },
              },
            };
            return JSON.stringify(query);
          },
          dataSrc: function (json) {
            json.recordsTotal = json.data.getOfflineBlendLogs.total;
            json.recordsFiltered = json.data.getOfflineBlendLogs.total;

            const currentPage = json.data.getOfflineBlendLogs.page;
            const recordsPerPage = json.data.getOfflineBlendLogs.perPage;

            const rows: any[] = [];
            const records = json.data.getOfflineBlendLogs.data;
            let counter = (currentPage - 1) * recordsPerPage + 1;
            for (const record of records) {
              const machineNames = record.machineIds.length > 0 ? machines.filter((machine: any) => record.machineIds.includes(machine.id)).map((machine: any) => machine.name ? machine.name : "N/A") : [];

              const locationNames = record.locationIds.length > 0 ? locations.filter((location: any) => record.locationIds.includes(location.id)).map((location: any) => location.name ? location.name : "N/A") : [];

              const customerNames = record.customerIds.length > 0 ? customers.filter((customer: any) => record.customerIds.includes(customer.id)).map((customer: any) => customer.name ? customer.name : "N/A") : [];

              const tempRecord = {
                id: counter,
                recordId: record.id,
                filePath: record?.filePath,
                fromDate: record?.fromDate
                  ? Moment.utc(record.fromDate)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "",
                toDate: record?.toDate
                  ? Moment.utc(record?.toDate)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "",
                machines: machineNames.length > 0 ? machineNames.join(",") : "",
                locations: locationNames.length > 0 ? locationNames.join(",") : "",
                customers:customerNames.length > 0 ? customerNames.join(",") : "",
                status: _state.statusDB.find(
                  (status: any) => status.id === record.status
                )?.value,
              };
              const subRecords = record.subTasks ?? "";
              for (const subRecord of subRecords) {
                subRecord["record"] = { ...subRecord };
              }
              rows.push(tempRecord);
              counter++;
          
            }
            return rows;
          },
        },
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "fromDate",
            render: function (data, type, row) {
              return row.fromDate;
            },
          },
          {
            name: "toDate",
            render: function (data, type, row) {
              return row.toDate;
            },
          },
          {
            name: "machines",
            render: function (data, type, row) {
              return row.machines ? row.machines : "*";
            },
          },
          {
            name: "locations",
            render: function (data, type, row) {
              return row.locations ? row.locations : "*";
            },
          },
          {
            name: "customers",
            render: function (data, type, row) {
              return row.customers ? row.customers : "*";
            },
          },
          {
            name: "status",
            render: function (data, type, row) {
              return row.status;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let html = "";
              if (row.status === "Ready for Download") {
                html +=
                  '<div><button class="btn btn-secondary btn downloadBtn p-2 px-4 m-1" data-key=\'' +
                  row.filePath +
                  "'><i class='fa fa-download' style='font-size:1rem;'></i></button></div>";
              }
              
              return html;
            },
          }
        ],
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          const title: any = [];
          $("#offlineBlendLogTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      $(".dt-buttons").remove();
    });
  };


  requestBlendLogData = async () => {
    this.setState({
      addLoading: true,
    });

    const {
      fromDate,
      toDate,
      machineIds,
      locationIds,
      customerIds,
      resetFromDate,
      resetToDate,
    } = this.state;

    if (!(fromDate && toDate)) {
      this.setState({
        addLoading: false,
      });
      toast.error("Please select from date and to date");
      return;
    }

    let $inputData: any = {
      fromDate: Moment.utc(fromDate).local().format("YYYY-MM-DD"),
      toDate: Moment.utc(toDate).local().format("YYYY-MM-DD"),
    };

    if (machineIds.length > 0) {
      $inputData["machineIds"] = machineIds;
    }

    if (customerIds.length > 0) {
      $inputData["customerIds"] = customerIds;
    }

    if (locationIds.length > 0) {
      $inputData["locationIds"] = locationIds;
    }

    try {
      const result = await requestForOfflineBlendLogData($inputData);
      const isSuccess = result?.data?.data?.requestForOfflineBlendLogData;

      if (!isSuccess) {
        const messages = result?.data?.errors.map((e: any) => e.message);
        toast.error(messages.join(","));
        return;
      }

      this.setState({
        machineIds: [],
        machineSelected: null,
        locationIds: [],
        locationSelected: null,
        customerIds: [],
        customerSelected: null,
        toDate: null,
        fromDate: null,
      });
      resetFromDate.current.state.inputValue = "";
      resetToDate.current.state.inputValue = "";
      toast.success("Request sent successfully!");
      this.renderDataTable([]);
    } catch (error) {
      toast.error("Failed to send request!");
      console.log("error", error);
    } finally {
      this.setState({
        addLoading: false,
      });
    }
  };

  handleFileDownload = async (path:string) => {
    try {
      const response = await getOfflineBlendLogFile(path);
      const {message,fileData} = response;
      if(!fileData) return;

      const binaryData = atob(fileData); 
      const buffer = new ArrayBuffer(binaryData.length);
      const view = new Uint8Array(buffer);

      let i = 0;
      for (const char of binaryData) {
        view[i++] = char.charCodeAt(0); 
      }

      const blob = new Blob([view], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const randomNumber = Math.random().toString(36).substring(2, 10);
      const timestamp = Date.now();

      link.href = url;
      link.download = `offline_blend_logs_${timestamp}_${randomNumber}.xlsx`; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success(message);
    } catch(error) {
      toast.error("Something went wrong. please try again")
    }
  }

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Download Blend Logs
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Download Blend Logs</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="" id="offline-blend-filter" aria-labelledby="offline-blend-filter">
          <div className="card mt-9">
            <div className="card-header pt-8">
              <h2 >Submit new request</h2>
            </div>
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    ref={this.state.resetFromDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.fromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        fromDate: dt,
                        toDate: null,
                      });
                    }}
                    inputProps={{ placeholder: "Select From Date" }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    ref={this.state.resetToDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.toDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        toDate: dt,
                      });
                    }}
                    isValidDate={(current) => {
                      if (!this.state.fromDate) return false;
                      const fromDate = moment(this.state.fromDate);
                      const maxDate = fromDate.clone().add(90, "days");
                      return (
                        current.isSameOrAfter(fromDate, "day") &&
                        current.isSameOrBefore(maxDate, "day")
                      );
                    }}
                    inputProps={{
                      placeholder: this.state.fromDate
                        ? "Select To Date"
                        : "Select From Date first",
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Machines</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder="Select Machines"
                    isMulti
                    name="machines"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        machineIds: value,
                        machineSelected: selected,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.machineSelected}
                    options={Array.from(this.state.machineDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: `${value.location?.name ?? ""} (${
                            value?.serialNumber ?? ""
                          })`,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Locations</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder="Select Locations"
                    isMulti
                    name="locations"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationIds: value,
                        locationSelected: selected,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customers</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder="Select Customers"
                    isMulti
                    name="customers"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerIds: value,
                        customerSelected: selected,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.customerSelected}
                    options={Array.from(this.state.customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.requestBlendLogData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">Request</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="table-responsive p-0 pb-2 fs-6"
                    id="data-table"
                  >
                    <table
                      id="offlineBlendLogTable"
                      className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                    >
                      <thead>
                        <tr>
                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            Sr.No
                          </th>
                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            From Date
                          </th>
                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            To Date
                          </th>

                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            Machines
                          </th>

                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            Locations
                          </th>

                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            Customers
                          </th>

                          <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                            Status
                          </th>
                          <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                         
                        </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                </div>
              </div>
        </div>
          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
      </>
    );
  }
}
