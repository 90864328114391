/**
 * @generated SignedSource<<f027d194861b9d0483436e7f0cbbc573>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LocationParentComponentQuery$variables = {
  isRegionalManager?: boolean | null;
};
export type LocationParentComponentQuery$data = {
  readonly getBusinessModelsList: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getDistributors: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getFilterProviders: ReadonlyArray<{
    readonly id: string;
    readonly title: string;
  }>;
  readonly getLocationList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
  readonly getLocationTypes: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly isDefault: boolean | null;
    readonly name: string;
  }>;
  readonly getMarketSegment: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getUsers: ReadonlyArray<{
    readonly firstName: string;
    readonly id: string;
    readonly isActive: boolean;
    readonly isRegionalManager: boolean;
    readonly lastName: string | null;
  }>;
};
export type LocationParentComponentQuery = {
  response: LocationParentComponentQuery$data;
  variables: LocationParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isRegionalManager"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DistributorObjectType",
    "kind": "LinkedField",
    "name": "getDistributors",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MarketSegmentObjectType",
    "kind": "LinkedField",
    "name": "getMarketSegment",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationTypeObjectType",
    "kind": "LinkedField",
    "name": "getLocationTypes",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionObjectType",
    "kind": "LinkedField",
    "name": "getRegions",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "FilterProviderObjectType",
    "kind": "LinkedField",
    "name": "getFilterProviders",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "BusinessModelsObjectType",
    "kind": "LinkedField",
    "name": "getBusinessModelsList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isRegionalManager",
        "variableName": "isRegionalManager"
      }
    ],
    "concreteType": "UserObjectType",
    "kind": "LinkedField",
    "name": "getUsers",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRegionalManager",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationParentComponentQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationParentComponentQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "a08f83afb782f8c5f24ff7832a4e4f7e",
    "id": null,
    "metadata": {},
    "name": "LocationParentComponentQuery",
    "operationKind": "query",
    "text": "query LocationParentComponentQuery(\n  $isRegionalManager: Boolean\n) {\n  getCustomerList {\n    id\n    name\n  }\n  getDistributors {\n    id\n    name\n  }\n  getMarketSegment {\n    id\n    name\n  }\n  getLocationTypes {\n    id\n    name\n    description\n    isDefault\n  }\n  getRegions {\n    id\n    name\n  }\n  getFilterProviders {\n    id\n    title\n  }\n  getBusinessModelsList {\n    id\n    name\n    description\n  }\n  getLocationList {\n    id\n    uuid\n    name\n  }\n  getUsers(isRegionalManager: $isRegionalManager) {\n    id\n    firstName\n    lastName\n    isActive\n    isRegionalManager\n  }\n}\n"
  }
};
})();

(node as any).hash = "846d9e0827e8e0f7f24cc0fed9213502";

export default node;
