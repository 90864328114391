import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import "./WorkOrder.css";
import mainLogo from "../../../images/smoodi_Logo.png";
import SignaturePad from "react-signature-pad-wrapper";
import Select from "react-select";
import { createWorkOrder, generateSignedUrl, getWorkOrderByIdForCreateWorkOrderForm } from "../../models/_worOrder";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import {
  MediaFile,
  validateObject,
  validateRequiredTasks,
  workOrderInitialValues,
  workOrderValidationSchema,
} from "./WorkOrderHelper";
import Checklist from "./Tabs/Checklist";
import FileUpload from "./Tabs/FileUpload";
import UserInfo from "./Tabs/UserInfo";
import { changeMachineLocation, getMachineStatusList, getMachinesNameAndSerials } from "../../models/_machine";
import { getLocationsForFilter,getUsers } from "../../models/_location";
import Modal from "react-responsive-modal";
import { FOLDER_NAME, WORK_ORDER_STATUS } from "../../../_metronic/helpers/enums";
import {  useNavigate } from "react-router-dom";
import * as Yup from "yup";

const InstallationWorkOrderForm: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const workOrderId:any = searchParams.get('hash_id');
  const [closeImages, setCloseImages] = useState<MediaFile[]>([]);
  const [storeImages, setStoreImages] = useState<MediaFile[]>([]);
  const [drainImages, setDrainImages] = useState<MediaFile[]>([]);
  const [serialNumberImages, setSerialNumberImages] = useState<MediaFile[]>([]);
  const [freezerDoorOpenImages, setFreezerDoorOpenImages] = useState<MediaFile[]>([]);
  const [closeResultImages, setCloseResultImages] = useState([]);
  const [freezerDoorOpenResultImages, setFreezerDoorOpenResultImages] = useState([]);
  const [storeResultImages, setStoreResultImages] = useState([]);
  const [drainResultImages, setDrainResultImages] = useState([]);
  const [serialNumberResultImages, setSerialNumberResultImages] = useState([]);
  const [closeUrl, setCloseUrl] = useState<string[]>([]);
  const [freezerDoorOpenUrl, setFreezerDoorOpenUrl] = useState<string[]>([]);
  const [storeUrl, setStoreUrl] = useState<string[]>([]);
  const [drainUrl, setDrainUrl] = useState<string[]>([]);
  const [serialNumberUrl, setSerialNumberUrl] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationDB, setLocationDB] = useState([]);
  const [managerDB, setManagerDB] = useState([]);
  const [machinesDB, setMachinesDB] = useState([]);
  const [installerDB, setInstallerDB] = useState([]);
  const [machineOneSelected, setMachineOneSelected] = useState<any>(null);
  const [machineTwoSelected, setMachineTwoSelected] = useState<any>(null);
  const [machineStatusIsDeployedId, setMachineStatusIsDeployedId] = useState<string>('');
  const [installerPersonName, setInstallerPersonName] = useState<string>('');
  const [isMachineDelployedModalOpen, setIsMachineDelployedModalOpen] = useState(false);
  const [isActiveInstallerNameInput, setIsActiveInstallerNameInput] = useState(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [submitClick, setSubmitClick] = useState(false);
  const resetLocation = React.useRef<any>(null);
  const resetDeploymentManager = React.useRef<any>(null);
  const resetPriority = React.useRef<any>(null);
  const resetNewTaskMachines = React.useRef<any>(null);
  const resetDate = React.useRef<any>(null);
  const resetDueDate = React.useRef<any>(null);
  const resetInstallerPerson = React.useRef<any>(null);
  const resetMachineOne = React.useRef<any>(null);
  const resetMachineTwo = React.useRef<any>(null);
  const [signatureError, setSignatureError] = useState(false);
  const sigPad = useRef<SignaturePad | null>(null);
  const storeManagerSigPad = useRef<SignaturePad | null>(null);
  const [isAllReadySubmitted, setIsAllReadySubmitted] = useState(false);
  const [workOrder, setWorkOrder] = useState<any>({});
  const navigate = useNavigate();

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };
  const storeManagerClearSignature = () => {
    if (storeManagerSigPad.current) {
      storeManagerSigPad.current.clear();
    }
  }
  const fetchData = async () => {
    try {
      const locationRecord = await getLocationsForFilter();
      const locationIdResults: any = locationRecord?.data
          ? locationRecord?.data
          : [];
        locationIdResults.sort(function (a: any, b: any) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setLocationDB(locationIdResults);

        const usersRecord = await getUsers();
        const deploymentManagerResults: any = usersRecord ? usersRecord : [];
        const sortedDeploymentManagers = deploymentManagerResults.filter((user: any) => user.isRegionalManager).sort(function (a: any, b: any) {
          if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
            return -1;
          }
          if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
  
        setManagerDB(sortedDeploymentManagers);

        const isActiveUsers = usersRecord?.filter((user:any) => user.isActive === true);

        isActiveUsers?.push({
          id: "other",
          firstName: "Other",
        });

        setInstallerDB(isActiveUsers);

        const machineRecord = await getMachinesNameAndSerials();
        const machineResult: any = machineRecord.data ? machineRecord.data : [];
        
        machineResult.sort(function (a: any, b: any) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        const machines = machineResult.filter((machine:any)=> machine.isRetired !== true);
        setMachinesDB(machines);

        const machineStatusRecord = await getMachineStatusList();
        const isDeployedMachineStatusRecord = machineStatusRecord?.filter((machine_status:any) => machine_status.name === "Deployed")
        setMachineStatusIsDeployedId(isDeployedMachineStatusRecord[0]?.id || "");
    } catch (error) {
      console.log('Error while fetchData', error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<{name: string; signedUrl: string; type: string}> => {
    const result = {name: "", signedUrl: "", type: ""};
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const selectedFilesArray: File[] = Array.from(selectedFiles);
      for (let i = 0; i < selectedFilesArray.length; i++) {
        const file = selectedFilesArray[i];
        const name = file.name;
        const type = file.type;
        try {
          const signedUrlResponse = await generateSignedUrl({
            file_name: name,
            file_type: type,
            folder_name: FOLDER_NAME.WORK_ORDER_IMAGES,
          });
          const signedUrl =
            signedUrlResponse?.data?.data?.generateSignedUrl?.url;

          const uploadedFile = await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadedFile.ok) {
            result.signedUrl = signedUrl;
            result.name = name;
            result.type = type;
          }
        } catch (err) {
          console.error("Error while handleFileUpload:", err);
          return result;
        }
      }
    }
    return result;
  };

  const handleCloseImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setCloseUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setCloseResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setCloseImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleStoreImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setStoreUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setStoreResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setStoreImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleFreezerDoorOpenImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setFreezerDoorOpenUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setFreezerDoorOpenResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setFreezerDoorOpenImages((previousImages) => previousImages.concat(allImages));
    }
  };


  const handleDrainImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setDrainUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setDrainResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setDrainImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleSerialNumberImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setSerialNumberUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setSerialNumberResultImages((previousImages) =>
        previousImages.concat(result)
      );
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setSerialNumberImages((previousImages) =>
        previousImages.concat(allImages)
      );
    }
  };

  const removeCloseImage = (image: any) => {
    setCloseImages(closeImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setCloseResultImages(
      closeResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeStoreImage = (image: any) => {
    setStoreImages(storeImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setStoreResultImages(
      storeResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeFreezerDoorOpenImage = (image: any) => {
    setFreezerDoorOpenImages(freezerDoorOpenImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setFreezerDoorOpenResultImages(
      freezerDoorOpenResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeDrainImage = (image: any) => {
    setDrainImages(drainImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setDrainResultImages(
      drainResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeSerialNumberImage = (image: any) => {
    setSerialNumberImages(
      serialNumberImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
    setSerialNumberResultImages(
      serialNumberResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const handleErrorCounter = (values: any) => {
    let count = 0;
    if (!values?.deploymentManagerId && !workOrderId) {
      count++;
    }
    if (!values?.locationId && !workOrderId) {
      count++;
    }
    if (!values?.installerId) {
      count++;
    }
    if (!values?.installerName && isActiveInstallerNameInput) {
      count++;
    }
    if (!values?.firstName && !workOrderId) {
      count++;
    }
    if (!values?.lastName && !workOrderId) {
      count++;
    }
    if (!values?.date) {
      count++;
    }
    if(!values?.machineId1){
      count++;
    }
    if (!values?.machineId2 && values?.isSecondMachineInstalled) {
      count++;
    }
    if (sigPad?.current?.isEmpty()) {
      count++;
      setSignatureError(true);
    } else {
      setSignatureError(false);
    }
    setErrorCount(count);
  };

  const getWorkOrderData = async () => {
    try {
      const response = await getWorkOrderByIdForCreateWorkOrderForm(workOrderId);
      if(response?.id){
        setIsAllReadySubmitted(response.status === WORK_ORDER_STATUS.COMPLETED);
        setWorkOrder(response)
        setMachineOneSelected({value:response?.machineId1})
      }
    } catch (error) {
      console.log('Error fetching work order data', error);
    }
  }

  useEffect(() => {
    fetchData();
    if(workOrderId) getWorkOrderData();
  }, []);

  const handleOnClickMachineDeployedModal = () => {
    if(machineOneSelected){
      setMachineOneSelected({
        ...machineOneSelected,
        isDeployed: false
      })
    }

    if(machineTwoSelected){
      setMachineTwoSelected({
        ...machineTwoSelected,
        isDeployed: false
      })
    }
    setIsMachineDelployedModalOpen(false);
  }

  const handleOnCloseMachineDeployedModal = () => {
    if (machineOneSelected && machineOneSelected.isDeployed) resetMachineOne.current.setValue("");
    if (machineTwoSelected && machineTwoSelected.isDeployed) resetMachineTwo.current.setValue("");
    setIsMachineDelployedModalOpen(false);
  }

  return !isAllReadySubmitted ? (
    <div className="wo-body">
      <div className="wo-logo-container">
        <img src={mainLogo} alt="Smoody Logo" className="wo-logo" />
      </div>

      {errorCount > 0 && submitClick && (
        <div className="error-banner">
          <span>
            There is <div className="error-badge">{errorCount}</div> error on
            this page.
          </span>
          {/* <button>See Errors</button> */}
        </div>
      )}

      <div className="wo-form-wrapper">
        <div className="wo-header-container">
          <h2 className="wo-header-title">Installation Work Order Form</h2>
        </div>
      </div>

      <div className="wo-form-wrapper">
        <div className="wo-form-container">
          <div className="row">
            <Formik
              initialValues={{
                ...workOrderInitialValues,
                locationId:workOrder?.locationId || "",
                deploymentManagerId: workOrder?.deploymentManagerId || "",
                machineId1: workOrder?.machineId1 || "",
              }}
              validationSchema={workOrderValidationSchema.shape({
                deploymentManagerId: workOrderId ? Yup.string().optional() : Yup.string().required(
                "Deployment manager is required"
                ),
                locationId: workOrderId ? Yup.string().optional() : Yup.string().required(
                "Location Name is required"
                ),
                firstName: workOrderId ? Yup.string().default(workOrder?.firstName) : Yup.string(),
                lastName: workOrderId ? Yup.string().default(workOrder?.lastName) : Yup.string(),
              })}
              onSubmit={async (values, {resetForm, setSubmitting}) => {
                setLoading(true);
                if (sigPad?.current?.isEmpty()) {
                  setSignatureError(true);
                  return false;
                }

                const signatureImg: any = {};
                let signatureUrls = "";
                if (sigPad.current) {
                  const signature = "signature.png";
                  const signatureImage = new Image();
                  signatureImage.src = sigPad.current.toDataURL();

                  const imageArrays = {
                    name: signature,
                    content: signatureImage.src,
                  };
                  signatureImg["signature"] = imageArrays;
                  const signatureDataURL = sigPad.current.toDataURL();
                  try {
                    const signatureSignedUrlResponse = await generateSignedUrl({
                      file_name: signature,
                      file_type: "image/png",
                      folder_name: FOLDER_NAME.WORK_ORDER_IMAGES,
                    });
                    const signatureSignedUrl =
                      signatureSignedUrlResponse?.data?.data?.generateSignedUrl
                        ?.url;

                    const signatureUploadedFile = await fetch(
                      signatureSignedUrl,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "image/png",
                        },
                        body: await fetch(signatureDataURL).then((res) =>
                          res.blob()
                        ),
                      }
                    );

                    if (signatureUploadedFile.ok) {
                      const uploadedSignatureUrl =
                        signatureSignedUrl.split("?")[0];

                      const signatureImageArray = {
                        name: signature,
                        content: uploadedSignatureUrl,
                      };
                      signatureUrls = signatureImageArray.content;
                    }
                  } catch (err) {
                    console.error("Error while uploading signature:", err);
                  }
                }
                const storeManagerSignatureImg: any = {};
                let storeManagerSignatureUrls = "";
                if (storeManagerSigPad.current) {
                  const signature = "signature.png";
                  const signatureImage = new Image();
                  signatureImage.src = storeManagerSigPad.current.toDataURL();

                  const imageArrays = {
                    name: signature,
                    content: signatureImage.src,
                  };
                  storeManagerSignatureImg["signature"] = imageArrays;
                  const signatureDataURL = storeManagerSigPad.current.toDataURL();
                  try {
                    const signatureSignedUrlResponse = await generateSignedUrl({
                      file_name: signature,
                      file_type: "image/png",
                      folder_name: FOLDER_NAME.WORK_ORDER_IMAGES,
                    });
                    const signatureSignedUrl =
                      signatureSignedUrlResponse?.data?.data?.generateSignedUrl
                        ?.url;

                    const signatureUploadedFile = await fetch(
                      signatureSignedUrl,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "image/png",
                        },
                        body: await fetch(signatureDataURL).then((res) =>
                          res.blob()
                        ),
                      }
                    );

                    if (signatureUploadedFile.ok) {
                      const uploadedSignatureUrl =
                        signatureSignedUrl.split("?")[0];

                      const signatureImageArray = {
                        name: signature,
                        content: uploadedSignatureUrl,
                      };
                      storeManagerSignatureUrls = signatureImageArray.content;
                    }
                  } catch (err) {
                    console.error("Error while uploading signature:", err);
                  }
                }
                const deploymentManagerId = workOrderId ? workOrder?.deploymentManagerId : values.deploymentManagerId;
                const locationId = workOrderId ? workOrder?.locationId : values.locationId;
                const $inputData: any = {
                  deploymentManagerId,
                  locationId,
                  closeUpMedia: closeUrl,
                  storeSetUpMedia: storeUrl,
                  drainSetUpMedia: drainUrl,
                  serialNumberMedia: serialNumberUrl,
                  freezerDoorOpenMedia: freezerDoorOpenUrl,
                  comment: values.comment,
                  isSecondMachineInstalled: values.isSecondMachineInstalled,
                  machineId1:values.machineId1 !== "" ? values.machineId1 : null,
                  machineId2:values.machineId2 !== "" ? values.machineId2 : null,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  storeContactName: values.storeContactName,
                  storeContactPhoneNumber: values.storeContactPhoneNumber,
                  storeContactRole: values.storeContactRole,
                  installerId: values?.installerId !== "other" ? values?.installerId : null,
                  installerName: values?.installerName || null,
                  date: values.date,
                  signature: signatureUrls,
                  storeManagerSignature: storeManagerSignatureUrls,
                };
                let machineIds = [];

                if(machineOneSelected && machineOneSelected.value) machineIds.push(machineOneSelected.value);
                if(machineTwoSelected && machineTwoSelected.value) machineIds.push(machineTwoSelected.value);

                if (validateObject(values.installationSteps)) {
                  $inputData["installationSteps"] = values.installationSteps;
                }
                if (validateObject(values.testPerformance)) {
                  $inputData["testPerformance"] = values.testPerformance;
                }
                if (validateObject(values.staffTraining)) {
                  $inputData["staffTraining"] = values.staffTraining;
                }
                if (validateObject(values.materialInstallation)) {
                  $inputData["materialInstallation"] =
                    values.materialInstallation;
                }
                const requiredTasks = validateRequiredTasks(
                  values.requiredTask
                );
                if (requiredTasks?.length) {
                  $inputData["requiredTask"] = requiredTasks;
                }

                if(machineIds?.length > 0){
                  let response = null;
                  for (const machine_id of machineIds) {
                      response = await changeMachineLocation(
                        machine_id,
                        locationId,
                        machineStatusIsDeployedId,
                        false,
                        "",
                        deploymentManagerId
                      );
                  }
                  if(response){
                    const changeMachineLocationsError = response?.data?.errors;
                    if(changeMachineLocationsError){
                      setSubmitting(false);
                      setLoading(false)
                      const messages = changeMachineLocationsError.map((e: any) => e.message);
                      toast.error(messages.join(","));
                      return;
                    }
                  }
                }
                if(workOrderId){
                  $inputData["id"] = workOrderId;
                }
                const result = await createWorkOrder($inputData);
                if (result?.data?.data?.createWorkOrder) {
                  navigate("/work-order",{
                    replace:true
                  });
                  toast.success("Work Order created successfully");
                  setLoading(false);
                  setSubmitClick(false);
                  resetLocation?.current?.setValue("");
                  resetDeploymentManager?.current?.setValue("");
                  resetInstallerPerson?.current?.setValue("");
                  resetMachineOne.current?.setValue("");
                  resetMachineTwo.current?.setValue("");
                  resetPriority?.current?.setValue("");
                  resetNewTaskMachines?.current?.setValue("");
                  resetDate.current.state.inputValue = "";
                  resetDueDate.current.state.inputValue = "";
                  setInstallerPersonName("")
                  clearSignature();
                  setCloseUrl([]);
                  setFreezerDoorOpenUrl([]);
                  setCloseImages([]);
                  setFreezerDoorOpenImages([]);
                  setStoreUrl([]);
                  setStoreImages([]);
                  setDrainUrl([]);
                  setDrainImages([]);
                  setSerialNumberUrl([]);
                  setSerialNumberImages([]);
                  setMachineOneSelected(null);
                  setMachineTwoSelected(null);
                  clearSignature();
                  storeManagerClearSignature();
                  setSignatureError(false);
                  setSubmitting(false);
                  setLoading(false);
                  resetForm({
                    values:{
                      ...workOrderInitialValues,
                      locationId: "",
                      deploymentManagerId:  "",
                      machineId1: "",
                      firstName:  "",
                      lastName: "",
                    }
                  })
                } else {
                  setSubmitting(false);
                  setLoading(false);
                  const messages = result?.data?.errors.map(
                    (e: any) => e.message
                  );
                  toast.error(messages.join(","));
                }
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => {
                return (
                  <div>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="col-6">
                        <div
                          className={clsx("wo-form-group", {
                            "error-box":
                              Boolean(errors.deploymentManagerId) &&
                              touched.deploymentManagerId,
                          })}
                        >
                          <label
                           htmlFor="deploymentManagerId"
                            className="wo-label required"
                          >
                            Deployment Manager
                          </label>
                          <Select
                            ref={resetDeploymentManager}
                            className={clsx("react-select", {
                              "is-invalid":
                                Boolean(errors.deploymentManagerId) &&
                                touched.deploymentManagerId,
                            })}
                            classNamePrefix="my-react-select"
                            id="deploymentManagerId"
                            name="deploymentManagerId"
                            value={managerDB
                              .map((value: any) => ({
                                value: value.id,
                                label: value.firstName,
                              }))
                              .find((option: any) => option.value === workOrder?.deploymentManagerId)}
                            placeholder="Select Deployment Manager"
                            onChange={(e: any) => {
                              setFieldValue("deploymentManagerId", e.value);
                              values.deploymentManagerId = e.value;
                              handleErrorCounter(values);
                            }}
                            isOptionDisabled={(option) => {
                              if(workOrderId){
                                return option.value !== workOrder?.locationId;
                              }
                              return false;
                            }}
                            options={Array.from(managerDB).map((value: any) => {
                              return {
                                value: value.id,
                                label: value.firstName,
                              };
                            })}
                          />
                          <div
                            className={clsx("", {
                              "wo-error mt-4":
                                Boolean(errors.deploymentManagerId) &&
                                touched.deploymentManagerId,
                            })}
                          >
                            <i
                              className="fa fa-exclamation-circle me-1 ms-2 error-icon"
                              aria-hidden="true"
                            ></i>
                            <ErrorMessage name="deploymentManagerId" />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div
                          className={clsx("wo-form-group", {
                            "error-box":
                              Boolean(errors.locationId) && touched.locationId,
                          })}
                        >
                          <label
                            htmlFor="locationId"
                            className="wo-label required"
                          >
                            Location Name
                          </label>
                          <Select
                            ref={resetLocation}
                            className={clsx("react-select", {
                              "is-invalid":
                                Boolean(errors.locationId) &&
                                touched.locationId,
                            })}
                            value={locationDB
                              .map((value: any) => ({
                                value: value.id,
                                label: value.name,
                              }))
                              .find((option: any) => option.value === workOrder?.locationId)}
                            classNamePrefix="my-react-select"
                            id="locationId"
                            name="locationId"
                            placeholder="Select Location"
                            onChange={(e: any) => {
                              setFieldValue("locationId", e.value);
                              values.locationId = e.value;
                              handleErrorCounter(values);
                            }}
                            options={locationDB.map(
                              (value: any) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              }
                            )}
                            isOptionDisabled={(option) => {
                              if(workOrderId){
                                return option.value !== workOrder?.locationId;
                              }
                              return false;
                            }}
                          />
                          <div
                            className={clsx("", {
                              "wo-error mt-4":
                                Boolean(errors.locationId) &&
                                touched.locationId,
                            })}
                          >
                            {" "}
                            <i
                              className="fa fa-exclamation-circle ms-2 error-icon"
                              aria-hidden="true"
                            ></i>{" "}
                            <ErrorMessage name="locationId" />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div
                          className={clsx("wo-form-group", {
                            "error-box":
                              Boolean(errors.installerId) &&
                              touched.installerId,
                          })}
                        >
                          <label
                            htmlFor="installerPerson"
                            className="wo-label required"
                          >
                            Installer Person
                          </label>
                          <Select
                            ref={resetInstallerPerson}
                            className={clsx("react-select", {
                              "is-invalid":
                                Boolean(errors.installerId) &&
                                touched.installerId,
                            })}
                            classNamePrefix="my-react-select"
                            id="installerId"
                            key={"installerId"}
                            name="installerId"
                            placeholder="Select Installer Person"
                            onChange={(e: any) => {
                              setFieldValue("installerId", e.value);
                              setIsActiveInstallerNameInput(e.value === "other" ? true : false);
                              values.installerId = e.value;
                              handleErrorCounter(values);
                            }}
                            options={Array.from(installerDB).map((value: any) => {
                              return {
                                value: value.id,
                                label: value.firstName,
                              };
                            })}
                          />
                          <div
                            className={clsx("", {
                              "wo-error mt-4":
                                Boolean(errors.installerId) &&
                                touched.installerId,
                            })}
                          >
                            <i
                              className="fa fa-exclamation-circle me-1 ms-2 error-icon"
                              aria-hidden="true"
                            ></i>
                            <ErrorMessage name="installerId" />
                          </div>
                        </div>
                      </div>
                      {isActiveInstallerNameInput && (
                        <div className="col-6">
                            <div className={clsx("wo-form-group p-2 pe-xl-5 pb-2 mt-3", {
                              "error-box":
                                Boolean(errors.installerName) &&
                                touched.installerName,
                              })}>
                                <label htmlFor="installerName1">
                                  Other Installer Person Name
                                </label>
                                <input
                                  id="installerName"
                                  type="text"
                                  name="installerName"
                                  placeholder="Installer Name"
                                  onChange={(e: any) => {
                                    setInstallerPersonName(e.target.value)
                                    setFieldValue("installerName", e.target.value);
                                    values.installerName = e.target.value;
                                    handleErrorCounter(values);
                                  }}
                                  value={installerPersonName}
                                  className={clsx("form-control", {
                                    "error-input wo-input":
                                      Boolean(errors.installerName) &&
                                      touched.installerName,
                                  })}
                                />
                                <div
                                  className={clsx("", {
                                    "wo-error mt-4":
                                      Boolean(errors.installerName) &&
                                      touched.installerName,
                                  })}
                                >
                                  <i
                                    className="fa fa-exclamation-circle me-1 ms-2 error-icon"
                                    aria-hidden="true"
                                  ></i>
                                  <ErrorMessage name="installerName" />
                                </div>
                            </div>
                        </div>
                        )}

                      <div
                        style={{
                          borderTop: "1px solid #dbdce4",
                          marginTop: "3rem",
                          marginBottom: "3rem",
                        }}
                      ></div>

                      <Checklist
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        resetDueDate={resetDueDate}
                        resetPriority={resetPriority}
                        resetNewTaskMachines={resetNewTaskMachines}
                        machinesDB = {machinesDB}
                        machineOneSelected = {machineOneSelected}
                        machineTwoSelected = {machineTwoSelected}
                        setMachineOneSelected = {setMachineOneSelected}
                        setMachineTwoSelected = {setMachineTwoSelected}
                        resetMachineOne = {resetMachineOne}
                        resetMachineTwo = {resetMachineTwo}
                        setIsMachineDelployedModalOpen = {setIsMachineDelployedModalOpen}
                        handleErrorCounter = {handleErrorCounter}
                        errors = {errors}
                        touched = {touched}
                      />

                      <FileUpload
                        handleCloseImageChange={handleCloseImageChange}
                        closeImages={closeImages}
                        removeCloseImage={removeCloseImage}
                        handleFreezerDoorOpenImageChange={handleFreezerDoorOpenImageChange}
                        freezerDoorOpenImages={freezerDoorOpenImages}
                        removeFreezerDoorOpenImage={removeFreezerDoorOpenImage}
                        handleStoreImageChange={handleStoreImageChange}
                        storeImages={storeImages}
                        removeStoreImage={removeStoreImage}
                        handleDrainImageChange={handleDrainImageChange}
                        drainImages={drainImages}
                        removeDrainImage={removeDrainImage}
                        handleSerialNumberImageChange={handleSerialNumberImageChange}
                        serialNumberImages={serialNumberImages}
                        removeSerialNumberImage={removeSerialNumberImage}
                      />

                      <UserInfo
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        workOrderId = {workOrderId}
                        workOrder = {workOrder}
                        handleErrorCounter={handleErrorCounter}
                        resetDate={resetDate}
                        signatureError={signatureError}
                        submitClick={submitClick}
                        sigPad={sigPad}
                        storeManagerSigPad={storeManagerSigPad}
                        clearSignature={clearSignature}
                        storeManagerClearSignature={storeManagerClearSignature}
                      />

                      <div
                        style={{
                          borderTop: "1px solid #dbdce4",
                          marginTop: "3rem",
                          marginBottom: "3rem",
                        }}
                      ></div>
                      <div className="button-container">
                        <button
                          type="submit"
                          className="btn btn-primary me-4 fs-4"
                          onClick={(e) => {
                            setSubmitClick(true);
                            handleErrorCounter(values);
                          }}
                        >
                          {loading ? 
                            <span
                              className="indicator-progress"
                              style={{display: "block"}}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          :<span className="indicator-label">Submit</span>}
                        </button>
                      </div>
                    </form>
                  </div>
                );
              }}
            </Formik>
            <Modal
              open={isMachineDelployedModalOpen}
              onClose={handleOnCloseMachineDeployedModal}
              role="dialog"
              center
            >
              <div className="modal-header p-4">
                <h4 className="modal-title fs-3">Deployment Confirmation</h4>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <p className="m-4">The selected machine is already deployed. Are you sure you want to continue?</p>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center p-4">
              <button
                type="button"
                className="btn btn-secondary fs-5 me-2"
                onClick={handleOnCloseMachineDeployedModal}
              >
                No
              </button>
              <button
                type="button"
                onClick={handleOnClickMachineDeployedModal}
                className="btn btn-danger fs-5"
              >
                Yes
              </button>
            </div>
            </Modal>
            <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ):(<div className="container d-flex align-items-center justify-content-center vh-100">
    <div className="card shadow-lg p-4 text-center" style={{ maxWidth: "500px" }}>
      <div className="card-body">
        <div className="mb-4">
          <img
            src={mainLogo}
            alt="Smoody Logo"
            className="img-fluid"
            style={{ maxWidth: "150px" }}
          />
        </div>

        <h2 className="card-title mb-4 text-primary">
          This work order already submitted
        </h2>

        <p className="card-text text-muted">
          Thank you for submitting your work order. Our team is already processing it. If you have
          any questions, feel free to contact us.
        </p>

        <a href="/dashboard" className="btn btn-primary mt-3">
          Back to Dashboard
        </a>
      </div>
    </div>
  </div>);
};

export default InstallationWorkOrderForm;
