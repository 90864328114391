import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import LocationsList from "./LocationsList";
import {
  Location,
  LocationType,
  MarketSegment,
  Region,
  User,
} from "../Machines/MachineParentComponent";
import { Customer, Distributor } from "../Customers/CustomerParentComponent";

export interface FilterProvider {
  id: string;
  title: string;
}

export interface BusinessModel {
  id: string;
  name: string;
  description: string;
}

interface QueryResponse {
  getCustomerList: Customer[];
  getDistributors: Distributor[];
  getMarketSegment: MarketSegment[];
  getLocationTypes: LocationType[];
  getRegions: Region[];
  getFilterProviders: FilterProvider[];
  getBusinessModelsList: BusinessModel[];
  getLocationList: Location[];
  getUsers: User[];
}

export type LocationParentComponentQuery = OperationType & {
  response: QueryResponse;
  variables: {
    isRegionalManager: boolean;
  };
};

class LocationParentComponent extends React.Component {
  render() {
    return (
      <QueryRenderer<LocationParentComponentQuery>
        environment={environment}
        query={graphql`
          query LocationParentComponentQuery($isRegionalManager: Boolean) {
            getCustomerList {
              id
              name
            }
            getDistributors {
              id
              name
            }
            getMarketSegment {
              id
              name
            }
            getLocationTypes {
              id
              name
              description
              isDefault
            }
            getRegions {
              id
              name
            }
            getFilterProviders {
              id
              title
            }
            getBusinessModelsList {
              id
              name
              description
            }
            getLocationList {
              id
              uuid
              name
            }
            getUsers(isRegionalManager: $isRegionalManager) {
              id
              firstName
              lastName
              isActive
              isRegionalManager
            }
          }
        `}
        variables={{ isRegionalManager: true }}
        render={({ error, props }) => {
          if (error) {
            return <div className="error">Error: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }

          const {
            getCustomerList,
            getDistributors,
            getMarketSegment,
            getLocationTypes,
            getRegions,
            getFilterProviders,
            getBusinessModelsList,
            getLocationList,
            getUsers,
          } = props;

          return (
            <LocationsList
              customers={getCustomerList}
              distributors={getDistributors}
              marketSegments={getMarketSegment}
              locationTypes={getLocationTypes}
              regions={getRegions}
              filterProviders={getFilterProviders}
              businessModels={getBusinessModelsList}
              locations={getLocationList}
              users={getUsers}
            />
          );
        }}
      />
    );
  }
}

export default LocationParentComponent;
