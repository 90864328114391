import React, { ChangeEvent, useEffect, useState } from "react";
import { ErrorMessage, FieldArray } from "formik";
import clsx from "clsx";
import {
  getMachineService,
  getMarketing,
  getOperations,
} from "../../../models/_tickets";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getMarketingMaterialsForFilter } from "../../../models/_marketingMaterial";

const Step3 = (props: {
  formValues: any;
  formError: any;
  formTouched: any;
  handleChange: any;
  setFieldValue: any;
  setSelectedImages: any;
  selectedImages: any;
  resetClosingDate: any;
  onSelectFile: (e: ChangeEvent<HTMLInputElement>) => void;
  deleteHandler: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { formValues,formError,formTouched,handleChange,setFieldValue,selectedImages,onSelectFile,deleteHandler,resetClosingDate } = props;
  const [machineServicesDB, setMachineServicesDB] = useState([]);
  const [operationDB, setOperationDB] = useState([]);
  const [marketingDB, setMarketingDB] = useState([]);
  const [materialDB, setMaterialDB] = useState([]);
  const [cleanlinessDB] = useState([
    {
      id: "GREAT",
      name: "Great",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "BAD",
      name: "Bad",
    },
  ]);
  const [trafficRateDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [enthusiasmDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [satisfactionDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [closedServiceTypeDB] = useState([
    {
      id: "ON_SITE",
      name: "On site",
    },
    {
      id: "REMOTE",
      name: "Remote",
    },
  ]);

  const fetchData = async () => {
    try {
      const machineServiceRecord = await getMachineService();
      const machineServiceResults: any = machineServiceRecord
        ? machineServiceRecord
        : [];
      machineServiceResults.push({
        id: "other",
        name: "Other",
      });

      const operationRecord = await getOperations();
      const operationResults: any = operationRecord ? operationRecord : [];
      operationResults.push({
        id: "other",
        name: "Other",
      });

      const marketingRecord = await getMarketing();
      const marketingResults: any = marketingRecord ? marketingRecord : [];
      marketingResults.push({
        id: "other",
        name: "Other",
      });
      const materialRecord = await getMarketingMaterialsForFilter();
      const materialResult: any = materialRecord ? materialRecord : [];

      setMachineServicesDB(machineServiceResults);
      setOperationDB(operationResults);
      setMarketingDB(marketingResults);
      setMaterialDB(materialResult);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        {formValues.ticketType === "REACTIVE_MAINTANANCE" ||
          formValues.ticketType === "LAB" ||
          formValues.ticketType === "PREVENTIVE_MAINTANANCE" ? (
          <>
            <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-2">
              <div className="accordion">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    data-bs-toggle="tooltip"
                    title="Click here to add recommneded services"
                    id="machine-services"
                  >
                    <button
                      className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#machine-services-needed"
                      aria-expanded="false"
                      aria-controls="machine-services-needed"
                    >
                      Recommneded Services
                    </button>
                  </h2>
                  <div
                    id="machine-services-needed"
                    className="accordion-collapse collapse"
                    aria-labelledby="machine-services"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pb-1 p-3">
                      <div className="row">
                        <FieldArray name="machineServices">
                          {(arrayHelpers) => {
                            return (
                              <>
                                {Array.from(machineServicesDB).map(
                                  (value: any, index) => {
                                    return (
                                      <div className="col-lg-6 col-md-6 col-12">
                                        <label
                                          key={index}
                                          className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                        >
                                          <input
                                            id="machineServices"
                                            className="form-check-input mb-2 me-1"
                                            type="checkbox"
                                            name={value.id}
                                            value={value.id}
                                            onChange={(e) => {
                                              if (e.target.checked)
                                                arrayHelpers.push(
                                                  e.target.value
                                                );
                                              else {
                                                let idx = -1;
                                                arrayHelpers.form.values.machineServices.forEach(
                                                  (e: any, index: number) => {
                                                    if (e === value.id) {
                                                      idx = index
                                                    }
                                                  }
                                                );

                                                arrayHelpers.remove(idx);
                                              }
                                            }}
                                          />
                                          <span className="ms-2 mb-1 fs-5 form-check-label">
                                            {value.name}
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            );
                          }}
                        </FieldArray>
                        {formValues.machineServices.includes("other") && (
                          <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                            <label className="form-label required fw-bold fs-4">
                              Other Machine services
                            </label>
                            <input
                              autoComplete="off"
                              id="services_other"
                              name="services_other"
                              className="form-control"
                              value={formValues.services_other}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-2">
              <div className="accordion">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    data-bs-toggle="tooltip"
                    title="Click here to add recommended operations"
                    id="operations"
                  >
                    <button
                      className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#operations-needed"
                      aria-expanded="false"
                      aria-controls="operations-needed"
                    >
                      Recommended operations
                    </button>
                  </h2>
                  <div
                    id="operations-needed"
                    className="accordion-collapse collapse"
                    aria-labelledby="operations"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pb-1 p-3">
                      <div className="row">
                        <FieldArray name="operations">
                          {(arrayHelpers) => {
                            return (
                              <>
                                {Array.from(operationDB).map(
                                  (value: any, index) => {
                                    return (
                                      <div className="col-lg-6 col-md-6 col-12">
                                        <label
                                          className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                          key={index}
                                        >
                                          <input
                                            id="operations"
                                            type="checkbox"
                                            name={value.id}
                                            className="form-check-input mb-2 me-1"
                                            value={value.id}
                                            onChange={(e) => {
                                              if (e.target.checked)
                                                arrayHelpers.push(
                                                  e.target.value
                                                );
                                              else {

                                                let idx = -1;
                                                arrayHelpers.form.values.operations.forEach(
                                                  (e: any, index: number) => {
                                                    if (e === value.id) {
                                                      idx = index
                                                    }
                                                  }
                                                );

                                                arrayHelpers.remove(idx);
                                              }
                                            }}
                                          />
                                          <span className="ms-1 mb-2 fs-5 form-check-label">
                                            {value.name}
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                      {formValues.operations.includes("other") && (
                        <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                          <label className="form-label required fw-bold fs-4">
                            Other Operations
                          </label>
                          <input
                            autoComplete="off"
                            id="operation_other"
                            name="operation_other"
                            className="form-control"
                            value={formValues.operation_other}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {formValues.ticketType === "MARKETING_OTHER" && (
          <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-2">
            <div className="accordion">
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  data-bs-toggle="tooltip"
                  title="Click here to add marketing purpose"
                  id="marketing"
                >
                  <button
                    className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#marketing-purpose"
                    aria-expanded="false"
                    aria-controls="marketing-purpose"
                  >
                    Marketing Purpose
                  </button>
                </h2>
                <div
                  id="marketing-purpose"
                  className="accordion-collapse collapse"
                  aria-labelledby="marketing"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body pb-1 p-3">
                    <div className="row">
                      <FieldArray name="marketingOrOthers">
                        {(arrayHelpers) => {
                          return (
                            <>
                              {Array.from(marketingDB).map(
                                (value: any, index) => {
                                  return (
                                    <div className="col-lg-6 col-md-6 col-12">
                                      <label
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2"
                                        key={index}
                                      >
                                        <input
                                          id="marketingOrOthers"
                                          type="checkbox"
                                          name={value.id}
                                          className="form-check-input mb-2 me-1"
                                          value={value.id}
                                          onChange={(e) => {
                                            if (e.target.checked)
                                              arrayHelpers.push(e.target.value);
                                            else {
                                              let idx = -1;
                                              arrayHelpers.form.values.marketingOrOthers.forEach(
                                                (e: any, index: number) => {
                                                  if (e === value.id) {
                                                    idx = index
                                                  }
                                                }
                                              );

                                              arrayHelpers.remove(idx);
                                            }
                                          }}
                                        />
                                        <span className="ms-1 mb-2 fs-5 form-check-label">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                    {formValues.marketingOrOthers?.includes("other") && (
                      <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                        <label className="form-label required fw-bold fs-4">
                          Other Marketing
                        </label>
                        <input
                          autoComplete="off"
                          id="marketing_other"
                          name="marketing_other"
                          className="form-control"
                          value={formValues.marketing_other}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-3 ">
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mb-lg-4">
          <label className="form-label  fw-bold fs-4">Comments</label>
          <textarea
            className="form-control"
            name="comment"
            id="comment"
            value={formValues.comment}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mb-lg-4">
          <label className="form-label  fw-bold fs-4">Chat Description</label>
          <textarea
            className="form-control"
            name="chatDescription"
            id="chatDescription"
            value={formValues.chatDescription}
            onChange={handleChange}
          />
        </div> */}
        {formValues.ticketType === "MARKETING_OTHER" && (
          <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mt-2">
            <div className="accordion">
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  data-bs-toggle="tooltip"
                  title="Click here to add marketing materials used"
                  id="marketing-materials"
                >
                  <button
                    className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#marketing-materials-used"
                    aria-expanded="false"
                    aria-controls="marketing-materials-used"
                  >
                    Marketing Materials Used
                  </button>
                </h2>
                <div
                  id="marketing-materials-used"
                  className="accordion-collapse collapse"
                  aria-labelledby="marketing-materials"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body pb-1 p-3">
                    <div className="row">
                      <FieldArray name="marketingMaterials">
                        {(arrayHelpers) => {
                          return (
                            <>
                              {Array.from(materialDB).map(
                                (value: any, index) => {
                                  return (
                                    <div className="col-lg-6 col-md-6 col-12">
                                      <label
                                        key={index}
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                      >
                                        <input
                                          id="marketingMaterials"
                                          className="form-check-input mb-2 me-1"
                                          type="checkbox"
                                          name={value.id}
                                          value={value.id}
                                          onChange={(e) => {
                                            if (e.target.checked)
                                              arrayHelpers.push(e.target.value);
                                            else {

                                              let idx = -1;
                                              arrayHelpers.form.values.marketingMaterials.forEach(
                                                (e: any, index: number) => {
                                                  if (e === value.id) {
                                                    idx = index
                                                  }
                                                }
                                              );

                                              arrayHelpers.remove(idx);
                                            }
                                          }}
                                        />
                                        <span className="ms-2 mb-1 fs-5 form-check-label">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
          <label className="form-label  fw-bold fs-4">Upload Images & Videos</label>
          <div className="btn btn-bg-light fs-4 w-100 p-3 m-0 border">
            <label
              className="form-label fs-4 mb-0 text-dark"
              style={{ cursor: "pointer" }}
            >
              Click here to upload images & videos
              <input
                id="upload"
                type="file"
                accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                name="uploadImageVideo"
                className="form-control d-none"
                onChange={onSelectFile}
              />
            </label>
          </div>
          {selectedImages.length > 0 && (
            <>
              {selectedImages &&
                selectedImages.map((image: any, index: any) => {
                  return (
                    <div
                      key={image}
                      className="img-box mt-3 ms-2 justify-content-center"
                    >
                      {image["type"] === "image/png" ||
                        image["type"] === "image/jpeg" ||
                        image["type"] === "image/webp" ||
                        image["type"] === "image/jpg" ? (
                        <img
                          src={image["content"]}
                          key={index}
                          width="150"
                          alt="Upload"
                          className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                        />
                      ) : (
                        <video
                          controls
                          src={image["content"]}
                          className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                          width="170"
                        />
                      )}
                      <button
                        onClick={() => deleteHandler(image)}
                        className="close-btn img-btn text-gray-700 text-hover-gray-800"
                      >
                        <i className="fa fa-close fs-5 pt-1" />
                      </button>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2 mb-8">
          <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
            <input
              className="form-check-input"
              type="checkbox"
              name="closeTicket"
              value="closeTicket"
              checked={formValues.closeTicket}
              onChange={handleChange}
            />
            <span
              className="form-label fw-bold mt-3 ms-2 fs-3"
              style={{ cursor: "pointer" }}
            >
              Close Ticket
            </span>
            <i
              className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
              data-bs-toggle="tooltip"
              title="Click to close the ticket"
            ></i>
          </label>
        </div>
      </div>
      {formValues.closeTicket === true ? (
        <>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 pb-2 mb-6">
              <label className="form-label required fw-bold fs-4 ps-1">
                How Ticket Closed
              </label>
              {Array.from(closedServiceTypeDB).map((value: any, index) => (
                <label
                  key={index}
                  className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2 mb-2"
                >
                  <input
                    id="howTicketClosed"
                    className="form-check-input"
                    type="radio"
                    name="howTicketClosed"
                    value={value.id}
                    checked={formValues.howTicketClosed === value.id}
                    onChange={() => setFieldValue("howTicketClosed", value.id)}
                  />
                  <span className="form-check-label fs-5 mb-0 ms-2">
                    {value.name}
                  </span>
                </label>
              ))}
              <div className="text-danger text-align-top-center ms-2 mt-1">
                <ErrorMessage name="howTicketClosed" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
              <label className="form-label required fw-bold fs-4">
                Closing Date & Time
              </label>
              <Datetime
                ref={resetClosingDate}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                closeOnSelect
                value={formValues.closingDateAndTime}
                onChange={(e: any) => {
                  const dt = e["_d"];
                  setFieldValue("closingDateAndTime", dt);
                }}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
              <label className="form-label required fw-bold fs-4">
                Time Spent On Task (Minutes)
              </label>
              <input
                autoComplete="off"
                type="number"
                className={clsx("form-control", {
                  "is-invalid":
                    Boolean(formError.timeSpentOnTask) &&
                    formTouched.timeSpentOnTask,
                })}
                value={formValues.timeSpentOnTask}
                name="timeSpentOnTask"
                id="timeSpentOnTask"
                onChange={handleChange}
              />
              <div className="text-danger text-align-top-center ms-1 mt-1">
                <ErrorMessage name="timeSpentOnTask" />
              </div>
            </div>

            {formValues.howTicketClosed === "ON_SITE" ? (
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                <label className="form-label required fw-bold fs-4">
                  Travel Time (Minutes)
                </label>
                <input
                  autoComplete="off"
                  type="number"
                  className={clsx("form-control", {
                    "is-invalid":
                      Boolean(formError.travelTime) && formTouched.travelTime,
                  })}
                  value={formValues.travelTime}
                  name="travelTime"
                  id="travelTime"
                  onChange={handleChange}
                />
                <div className="text-danger text-align-top-center ms-1 mt-2">
                  <ErrorMessage name="travelTime" />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
              <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                Customer Satisfaction
              </label>
              {Array.from(satisfactionDB).map((value: any, index) => (
                <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                  <label
                    className="form-check-label fs-5 mb-0 ms-2"
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="customerSatisfaction"
                      value={value.id}
                      checked={formValues.customerSatisfaction === value.id}
                      onChange={() =>
                        setFieldValue("customerSatisfaction", value.id)
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      {value.name}
                    </span>
                  </label>
                </div>
              ))}
            </div>

            {formValues.howTicketClosed !== "REMOTE" ? (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                  <label className="form-label fw-bold d-block fs-4 ps-1 pb-2">
                    Cleanliness
                  </label>
                  {Array.from(cleanlinessDB).map((value: any, index) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={index}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="cleanliness"
                          value={value.id}
                          checked={formValues.cleanliness === value.id}
                          onChange={() =>
                            setFieldValue("cleanliness", value.id)
                          }
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                  <label className="form-label fw-bold d-block fs-4 ps-1 pb-2">
                    Traffic Rate
                  </label>
                  {Array.from(trafficRateDB).map((value: any, index) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={index}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="trafficRate"
                          value={value.id}
                          checked={formValues.trafficRate === value.id}
                          onChange={() =>
                            setFieldValue("trafficRate", value.id)
                          }
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
              <label className="form-label fw-bold d-block fs-4 ps-1 pb-2">
                Staff Enthusiasm
              </label>
              {Array.from(enthusiasmDB).map((value: any, index) => (
                <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                  <label
                    className="form-check-label fs-5 mb-0 ms-2"
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="staffEnthusiasm"
                      value={value.id}
                      checked={formValues.staffEnthusiasm === value.id}
                      onChange={() =>
                        setFieldValue("staffEnthusiasm", value.id)
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      {value.name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Step3;
