import _ from "lodash";
import React ,{Suspense ,useCallback}from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getLocation } from "../../models/_logjob";
import Moment from "moment";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import {  ViewJob_getLogJobQuery } from './__generated__/ViewJob_getLogJobQuery.graphql';
import EditJobModal from './EditJobModal'
import  { Toaster } from "react-hot-toast";

export const getLogJobListById = graphql`
query ViewJob_getLogJobQuery($id:String!){
  getLogJob(id:$id){
    id
    uuid
    jobType
    locationId
    dateAndTime
    uploadImagesOrVideos {
      url
      type
    }
    marketingMaterials {
      id
      name
    }
    sanitizerStatus
    machineSerialNumber
    machineName
    additionalComments
    customerSatisfaction
    cleanliness
    trafficRate
    staffEnthusiasm
    status
    closingDateAndTime
    travelTime
    createdBy {
      id
      firstName
    }
  }
}
`

export const ViewJob = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [logjob, setLogjob] = React.useState<any>({});
  const [locationName, setLocationName] = React.useState("");
  const [editJobModal, setEditJobModal] = React.useState(false);
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState<any>(null);
 
  const splittedRoute = location.pathname.split("/").reverse()[0];
  if (!splittedRoute) {
    navigate("/job/list");
  }
  
  const JobRecord = useLazyLoadQuery<ViewJob_getLogJobQuery>(
    getLogJobListById,
  {
    id:splittedRoute ?? ""
  },refreshedQueryOptions ?? {})
  const result = JobRecord?.getLogJob ?? []

  let currentJobId = result?.id

  const fetchData = async () => {
   
      if (result === null) {
        navigate("/job/list");
      } else {
        const locationRecord = await getLocation();
        const locationData = locationRecord.data ?? []
        let location = "";
        for (const locationObj of locationData) {
      
          if (locationObj.id === result.locationId) {
            location = locationObj.name;
          }
        }
        setLocationName(location);
        setLogjob(result);
      }
  };

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);

  const handleCloseJob= () => {
    setEditJobModal(false)
    refresh()
  }
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);


  return (
    <Suspense>
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap"></div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item text-muted">
                <span className="bullet bg-gray-400 w-5px h-2px" />
                <a href="/job/list" className="text-muted ps-2 fs-5">
                  Jobs list
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item fs-5 text-dark">Job Detail</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-xl-4 mt-9">
        <div className="card">
          <div className="card-header cursor-pointer border-bottom-0 py-4">
            <div className="card-title m-0">
              <h3 className="fw-semibold m-0">Job Details - {logjob.uuid}</h3>
            </div>
            {logjob.status !== "COMPLETED" ? (
<>
              <button  data-bs-toggle="tooltip" title="Click here to edit job"
              onClick={() => {setEditJobModal(true)}}
              className="btn btn-secondary btn px-5 py-3 m-1">
               <i className="fa fa-pencil fs-4" ></i>
              </button>
             
              </>
            ) : (
              ""
            )}
          </div>
        
          {editJobModal && (
             <EditJobModal  editJobModal={editJobModal}  
             handleCloseJob={handleCloseJob}
             currentJobId={currentJobId}
             />
          )}
         
        </div>

        <div className="mt-1 row g-6  g-xl-9">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="card">
              <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Job Type
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.jobType ?? ""))}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Created By
                  </label>
                  <div className="col-lg-6">
                    <span className="fs-4 text-dark">
                      {logjob.createdBy?.firstName ?? ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Location Name
                  </label>
                  <div className="col-lg-6 fv-row">
                    <Link to={`/locations/list/history/${logjob.locationId}`} className="text-hover-primary">
                      <span className="text-primary fs-4">{locationName}</span>
                      <i className="fa fa-external-link ms-3"></i>
                    </Link>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Start Time
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.dateAndTime
                        ? Moment.unix(logjob.dateAndTime / 1000)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Status
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.status ?? ""))}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fw-semibold text-muted fs-5">
                    Comments
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.additionalComments ?? ""}
                    </span>
                  </div>
                </div>
                
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Marketing Materials Used
                  </label>
                  <div className="col-lg-6 fv-row">
                    {logjob.marketingMaterials?.length > 0
                      ? logjob.marketingMaterials?.map((element: any) => {
                          return (
                            <li className="fs-5 text-dark">{element.name}</li>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Cleanliness
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.cleanliness ?? ""))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="card">
              <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Customer Satisfaction
                  </label>
                  <div className="col-lg-6">
                    <span className="fs-5 text-dark">
                      {_.capitalize(
                        _.lowerCase(logjob.customerSatisfaction ?? "")
                      )}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Traffic Rate
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.trafficRate ?? ""))}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fw-semibold text-muted fs-5">
                    Staff Enthusiasm
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.staffEnthusiasm ?? ""))}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fw-semibold text-muted fs-5">
                    Status Of Sanitizer
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {_.capitalize(_.lowerCase(logjob.sanitizerStatus ?? ""))}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    End Time
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.closingDateAndTime
                        ? Moment.unix(logjob.closingDateAndTime / 1000)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Travel Time
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.travelTime ?? ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Machine Name
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.machineName ?? ""}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-6 fs-5 fw-semibold text-muted">
                    Machine Serial Number
                  </label>
                  <div className="col-lg-6 fv-row">
                    <span className="text-dark fs-5">
                      {logjob.machineSerialNumber ?? ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1 row g-6  g-xl-9">
          <div className="col-xl-6 col-lg-12 col-md-12 col-12 mt-2 pt-2">
            {logjob.uploadImagesOrVideos?.length > 0 && (
              <>
                <div className="card">
                  <div className="card-body">
                    <div className="btn-bg-light btn fs-2 fw-semibold text-start mb-2 border-0">
                      Job Images
                    </div>

                    <div className="rounded-1 mt-2 h-100 shadow-none">
                      <div className="row align-items-center">
                        {logjob.uploadImagesOrVideos?.map(
                          (image: any, index: any) => {
                            return (
                              <>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                  {image["type"] === "image/png" ||
                                  image["type"] === "image/jpeg" ||
                                  image["type"] === "image/webp" ||
                                  image["type"] === "image/jpg" ? (
                                    <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                      <img
                                        src={image["url"]}
                                        key={image}
                                        alt="Images"
                                        width="250"
                                        className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light mt-2"
                                      />
                                    </SlideshowLightbox>
                                  ) : (
                                    <video
                                      controls
                                      src={image["url"]}
                                      className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light mt-2"
                                      width="250"
                                    />
                                  )}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
    </Suspense>
  );
};
